import React, { useState, Suspense } from 'react';
import { Box, Typography, IconButton, Snackbar, Alert } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthButton from 'src/components/auth/AuthButton';
import AuthInput from 'src/components/auth/AuthInput';
import CircularProgress from '@mui/material/CircularProgress';
import { useResetPassword } from 'src/hooks/mutations/auth/useResetPassword';
import { ResetPasswordForm } from 'src/types/auth';
import useIsSmallScreen from 'src/theme/mobileTheme';
import PasswordStrengthBar from 'src/components/auth/PasswordStrengthBar';
import {
  validatePassword,
  validateConfirmPassword,
} from 'src/utils/validations';
const SuccessAlert = React.lazy(() => import('src/components/SuccessAlert'));

export const ResetPasswordPage: React.FC = () => {
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorPassword, setErrorPassword] = useState<string>(
    'Por favor, insira sua senha.',
  );
  const [errorConfirmPassword, setErrorConfirmPassword] = useState<string>(
    'Por favor, confirme sua senha.',
  );
  const [showValidateError, setShowValidateError] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const {
    mutate: resetPassword,
    isPending,
    isError,
    error,
  } = useResetPassword();
  const isSmallScreen = useIsSmallScreen();
  const token = new URLSearchParams(location.search).get('token');
  const emailUri = new URLSearchParams(location.search).get('emailUri');

  const isValidEmail = (email: string | null): boolean => {
    if (!email) return false;
    return /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(email);
  };

  const isValidToken = (token: string | null): boolean => {
    return token !== null && token.length === 64;
  };

  const isEmailValid = isValidEmail(emailUri);
  const isTokenValid = isValidToken(token);

  if (!token || !isTokenValid || !emailUri || !isEmailValid) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          bgcolor: '#F8F8F8',
          px: 3,
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          textAlign="center"
          color="error"
        >
          {!isTokenValid && !isEmailValid
            ? 'Token e E-mail inválidos ou não encontrados!'
            : !isTokenValid
              ? 'Token inválido ou não encontrado!'
              : 'Email inválido ou não encontrado!'}
        </Typography>
        <Typography variant="body1" textAlign="center" mt={1}>
          Verifique seu link ou solicite uma nova redefinição de senha.
        </Typography>
      </Box>
    );
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (errorPassword || errorConfirmPassword) {
      setShowValidateError(true);
      return;
    }

    const data: ResetPasswordForm = {
      email: emailUri,
      token: token,
      newPassword: password,
    };

    resetPassword(data, {
      onSuccess: () => {
        setShowSuccessAlert(true);
      },
    });
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      sx={{ bgcolor: ' #F8F8F8' }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit}
        bgcolor="white"
        p={4}
        borderRadius={2}
        width="100%"
        maxWidth="400px"
        sx={{
          [`${isSmallScreen}`]: {
            p: 3,
            maxWidth: '300px',
          },
          border: '1px solid #D1D5DB',
        }}
      >
        <Box display="flex" justifyContent="center" mb={3}>
          <Box
            component="img"
            src="/images/logo-todosganham.png"
            alt="Logo"
            style={{ height: '64px' }}
          />
        </Box>
        <Typography variant="h5" textAlign="center" gutterBottom>
          Criar nova senha
        </Typography>
        <Typography
          variant="body2"
          textAlign="center"
          color="textSecondary"
          mb={4}
        >
          Use pelo menos 8 caracteres, incluindo letras maiúsculas e minúsculas,
          números e símbolos.
        </Typography>

        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            mb: -1,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="body2"
            sx={{
              mb: 1,
              color: '#4B5563',
            }}
          >
            Nova senha
          </Typography>
          <Typography
            variant="body2"
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: '#715D5DCC',
              cursor: 'pointer',
            }}
            onClick={() => setShowPassword(!showPassword)}
          >
            <IconButton
              size="small"
              sx={{ color: '#715D5DCC', mr: 0 }}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
            {showPassword ? 'Ocultar' : 'Mostrar'}
          </Typography>
        </Box>

        <AuthInput
          name="password"
          type={showPassword ? 'text' : 'password'}
          variant="outlined"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            setErrorPassword(validatePassword(e.target.value));
            setErrorConfirmPassword(
              validateConfirmPassword(confirmPassword, e.target.value),
            );
          }}
          error={!!errorPassword && showValidateError}
          helperText={errorPassword && showValidateError ? errorPassword : ''}
        />
        <Typography
          variant="body2"
          sx={{
            mt: 1,
            color: '#4B5563',
          }}
        >
          Confirme a senha
        </Typography>
        <AuthInput
          name="confirmPassword"
          type={showPassword ? 'text' : 'password'}
          fullWidth
          variant="outlined"
          value={confirmPassword}
          onChange={(e) => {
            setConfirmPassword(e.target.value);
            setErrorConfirmPassword(
              validateConfirmPassword(e.target.value, password),
            );
          }}
          error={!!errorConfirmPassword && showValidateError}
          helperText={
            errorConfirmPassword && showValidateError
              ? errorConfirmPassword
              : ''
          }
        />
        <PasswordStrengthBar password={password} />
        <AuthButton
          type="submit"
          sx={{ mt: 1 }}
          disabled={
            isPending ||
            (!!errorPassword && showValidateError) ||
            (!!errorConfirmPassword && showValidateError)
          }
        >
          {isPending ? 'Mudando a senha...' : 'Mudar senha'}
        </AuthButton>
      </Box>
      {isError && error && (
        <Snackbar open={true} autoHideDuration={6000}>
          <Alert severity="error" sx={{ width: '100%' }}>
            {error.message || 'Ocorreu um erro inesperado'}
          </Alert>
        </Snackbar>
      )}
      {showSuccessAlert && (
        <Suspense fallback={<CircularProgress />}>
          <SuccessAlert
            message="Senha alterada com sucesso!"
            buttonText="Usar nova senha"
            onClose={() => {
              setShowSuccessAlert(false);
              navigate('/login');
            }}
          />
        </Suspense>
      )}
    </Box>
  );
};

export default ResetPasswordPage;
