import React from 'react';
import {
  Select,
  MenuItem,
  Box,
  FormControl,
  SelectChangeEvent,
} from '@mui/material';

interface PeriodFilterProps {
  onPeriodFilterChange: (periodFilterOption: Date) => void;
}

const getDateValue = (days: number) => {
  const date = new Date();
  date.setDate(date.getDate() - days);
  return date.toISOString().split('T')[0];
};

const PeriodFilter: React.FC<PeriodFilterProps> = ({
  onPeriodFilterChange,
}) => {
  const [periodFilterOptions, setPeriodFilterOptions] = React.useState<string>(
    getDateValue(30),
  );

  const handlePeriodFilterChange = (event: SelectChangeEvent<string>) => {
    const newPeriodFilterOptions = event.target.value;
    setPeriodFilterOptions(newPeriodFilterOptions);
    onPeriodFilterChange(new Date(newPeriodFilterOptions));
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <FormControl sx={{ minWidth: 150 }}>
        <Select
          className="h-8 lg:h-14"
          sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}
          value={periodFilterOptions}
          onChange={handlePeriodFilterChange}
        >
          <MenuItem value={getDateValue(1)}>Hoje</MenuItem>
          <MenuItem value={getDateValue(7)}>Última semana</MenuItem>
          <MenuItem value={getDateValue(30)}>Último mês</MenuItem>
          <MenuItem value={getDateValue(180)}>Últimos 6 meses</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default PeriodFilter;
