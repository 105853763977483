import { useMutation } from '@tanstack/react-query';
import { register } from 'src/api/auth';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RegisterForm, AuthResponse } from 'src/types/auth';
import {
  useProfessionsList,
  useProfessionOptions,
} from 'src/hooks/mutations/auth/useProfessions';
import useIsSmallScreen from 'src/theme/mobileTheme';
import {
  validateRegisterErrorForm,
  showRegisterErrorForm,
} from 'src/utils/validations';

export const useRegister = () => {
  const navigate = useNavigate();
  const { professionsList } = useProfessionsList();
  const isSmallScreen = useIsSmallScreen();
  const mutation = useMutation<AuthResponse, Error, RegisterForm>({
    mutationFn: register,
    onSuccess: () => {
      setShowSuccessAlert(true);
    },
  });
  const { professionOptions } = useProfessionOptions();

  const handleProfessionChange = (
    event: React.SyntheticEvent,
    newValue: string | null,
  ) => {
    setFormData({ ...formData, profession: newValue || '' });
  };

  const [formData, setFormData] = useState<RegisterForm>({
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    profession: '',
    phone: '',
    gender: '',
    birthDate: new Date(''),
  });

  const [validationErrors, setValidationErrors] =
    useState<validateRegisterErrorForm>({
      firstName: 'Por favor, insira seu nome.',
      lastName: 'Por favor, insira seu sobrenome.',
      email: 'Por favor, insira seu e-mail.',
      password: 'Por favor, insira sua senha.',
      confirmPassword: 'Por favor, confirme sua senha.',
      profession: 'Por favor, selecione sua profissão.',
      phone: 'Por favor, insira seu número de telefone.',
      gender: 'Selecione seu gênero.',
      birthDate: 'Selecione uma data válida.',
    });
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const [showErrors, setShowErrors] = useState<showRegisterErrorForm>({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
    confirmPassword: false,
    profession: false,
    phone: false,
    gender: false,
    birthDate: false,
  });
  const [showAllErrors, setShowAllErrors] = useState<boolean>();
  const [errorTermsAccepted, setErrorTermsAccepted] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);

  const handleShowAllErrors = () => {
    setShowErrors({
      firstName: true,
      lastName: true,
      email: true,
      password: true,
      confirmPassword: true,
      profession: true,
      phone: true,
      gender: true,
      birthDate: true,
    });
  };

  const handlePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (Object.values(validationErrors).some((error) => error !== '')) {
      handleShowAllErrors();
      setShowAllErrors(true);
      if (!termsAccepted) {
        setErrorTermsAccepted(true);
        return;
      }
      return;
    }
    if (!termsAccepted) {
      setErrorTermsAccepted(true);
      return;
    }
    const selectedProfession = professionsList.find(
      (profession) => profession.name === formData.profession,
    );

    mutation.mutate({
      ...formData,
      profession: selectedProfession?._id || 'ID_BELONGING_TO_OBJECT_OUTRO',
      username: `${formData.firstName} ${formData.lastName}`,
    });
  };

  return {
    navigate,
    isSmallScreen,
    professionOptions,
    handleProfessionChange,
    formData,
    setFormData,
    validationErrors,
    setValidationErrors,
    passwordVisible,
    confirmPassword,
    setConfirmPassword,
    showAllErrors,
    termsAccepted,
    setTermsAccepted,
    showErrors,
    setShowErrors,
    errorTermsAccepted,
    setErrorTermsAccepted,
    showSuccessAlert,
    setShowSuccessAlert,
    handlePasswordVisibility,
    handleSubmit,
    error: mutation.error,
    isError: mutation.isError,
    isPending: mutation.isPending,
  };
};
