const API_URL = process.env.REACT_APP_PUBLIC_API_BASE_URL;

export interface ProfessionForm {
  _id: string;
  name: string;
}

export const getProfessions = async (): Promise<ProfessionForm[]> => {
  if (!API_URL) {
    throw new Error('API_URL não configurada');
  }

  const res = await fetch(`${API_URL}/professions/`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  if (!res.ok) {
    const error = await res.json();
    throw new Error(error.error || 'Falha na busca de profissões');
  }

  return res.json();
};
