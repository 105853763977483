import {
  LoginForm,
  AuthResponse,
  RegisterForm,
  ForgotPasswordForm,
  ResetPasswordForm,
} from 'src/types/auth';

const API_URL = process.env.REACT_APP_PUBLIC_API_BASE_URL;

/**
 * Logs in a user by sending their credentials to the API.
 * @async
 * @function login
 * @param {LoginForm} data -The login form data cotaining username and password.
 * @returns {Promise<AuthResponse>} The authentication responde containing user details and token.
 * @throws {Error} - If the login request fails.
 */

export const login = async (data: LoginForm): Promise<AuthResponse> => {
  if (!API_URL) {
    throw new Error('API_URL não configurada');
  }

  const res = await fetch(`${API_URL}/auth/login`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });

  if (!res.ok) {
    const error = await res.json();

    switch (res.status) {
      case 401:
        throw new Error('Usuário/senha inválidos');
      case 500:
        throw new Error('Erro no servidor');
      case 404:
        throw new Error('Usuário não encontrado');
      default:
        throw new Error(error.error || 'Falha no login');
    }
  }

  return res.json();
};

/**
 * Registers a new user by sending their data to the API
 * @async
 * @function register
 * @param {RegisterForm} data - The registration form data containing user details.
 * @returns {Promise<AuthResponse>} The authentication response containing user details and .
 * @throws {Error} If the registration request fails.
 */
export const register = async (data: RegisterForm): Promise<AuthResponse> => {
  if (!API_URL) {
    throw new Error('API_URL não configurada');
  }

  const res = await fetch(`${API_URL}/auth/register`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });

  if (!res.ok) {
    const error = await res.json();
    switch (res.status) {
      case 401:
        throw new Error('Credenciais Inválidas');
      case 409:
        throw new Error('E-mail já está registrado');
      case 500:
        throw new Error('Erro no servidor');
      default:
        throw new Error(error.error || 'Falha no cadastro');
    }
  }

  return res.json();
};

/**
 * Sends a password reset request to the API
 * @async
 * @function ForgotPassword
 * @param {ForgotPasswordForm} data - The form data containing the user's email.
 * @returns {Promise<AuthResponse>} The authentication response containing user details and token.
 * @throws {Error} If the password reset request fails.
 * @example
 *   data = { email: 'test@example.com' }
 *   ForgotPassword(data)
 */
export const forgotPassword = async (
  data: ForgotPasswordForm,
): Promise<AuthResponse> => {
  if (!API_URL) {
    throw new Error('API_URL não configurada');
  }

  const res = await fetch(`${API_URL}/auth/forgot-password`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });

  if (!res.ok) {
    const error = await res.json();
    switch (res.status) {
      case 500:
        throw new Error('Erro no servidor');
      default:
        throw new Error(error.error || 'Falha no envio do e-mail');
    }
  }

  return res.json();
};

/**
 * Resets a user's password by sending the new password and token to the API.
 * @async
 * @function resetPassword
 * @param {ResetPasswordForm} data - The form data containing the new password and token.
 * @returns {Promise<AuthResponse>} The authentication response containing user details and token.
 * @throws {Error} If the password reset request fails.
 * @example
 *   data = { email: 'test@example.com', token: 'qwerty', newPassword: '12345678' }
 *   resetPassword(data)
 */
export const resetPassword = async (
  data: ResetPasswordForm,
): Promise<AuthResponse> => {
  if (!API_URL) {
    throw new Error('API_URL não configurada');
  }

  const res = await fetch(`${API_URL}/auth/reset-password`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });

  if (!res.ok) {
    const error = await res.json().catch(() => ({}));
    switch (res.status) {
      case 422:
        throw new Error('A nova senha deve ser diferente da senha atual');
      case 401:
        throw new Error('Token inválido ou expirado');
      case 500:
        throw new Error('Erro no servidor');
      default:
        throw new Error(error.error || 'Falha na redefinição da senha');
    }
  }

  if (res.status !== 204) {
    return res.json();
  }

  return {} as AuthResponse;
};
