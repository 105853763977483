//
export interface validateRegisterErrorForm {
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  phone: string;
  profession: string;
  email: string;
  gender: string;
  birthDate: string;
}

export interface showRegisterErrorForm {
  firstName: boolean;
  lastName: boolean;
  password: boolean;
  confirmPassword: boolean;
  phone: boolean;
  profession: boolean;
  email: boolean;
  gender: boolean;
  birthDate: boolean;
}

//
const ALLOWED_SPECIAL_CHARS = "~'`-";
const UNICODE_PATTERNS = {
  name: new RegExp(`^[A-Za-zÀ-ÖØ-öø-ÿ\\s${ALLOWED_SPECIAL_CHARS}]+$`),
  noEmoji: /^[^\u{1F000}-\u{2BFFE}]+$/u,
};
const validateText = (value: string): boolean => {
  return (
    UNICODE_PATTERNS.name.test(value) && UNICODE_PATTERNS.noEmoji.test(value)
  );
};
const namePattern = /^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/;

//
export const validateFistName = (firstName: string): string => {
  if (!firstName) return 'Por favor, insira seu nome.';
  if (
    firstName.length < 2 ||
    firstName.length > 50 ||
    !namePattern.test(firstName)
  )
    return 'O nome deve ter entre 2 e 50 caracteres e conter apenas letras.';
  if (!validateText(firstName))
    return 'O nome não pode conter caracteres especiais ou emojis.';
  return '';
};

//
export const validateLastName = (lastName: string): string => {
  if (!lastName) return 'Por favor, insira seu sobrenome.';
  if (
    lastName.length < 2 ||
    lastName.length > 50 ||
    !namePattern.test(lastName)
  )
    return 'O nome deve ter entre 2 e 50 caracteres e conter apenas letras.';
  if (!validateText(lastName))
    return 'O nome não pode conter caracteres especiais ou emojis.';
  return '';
};

//
const emailRegex =
  process.env.REACT_APP_ALLOW_EMAIL_SUBADDRESSING === 'true'
    ? /^[A-Za-z0-9._-]+(\+[A-Za-z0-9._-]+)?@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
    : /^[A-Za-z0-9._-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

export const validateEmail = (email: string): string => {
  if (!email) return 'Por favor, insira seu e-mail.';
  if (!emailRegex.test(email)) return 'Por favor, insira um e-mail válido.';
  return '';
};

//
export const validatePassword = (password: string): string => {
  if (!password) return 'Por favor, insira sua senha.';
  if (password.length < 8) return 'A senha deve ter no mínimo 8 caracteres!';
  if (/[\u0080-\uFFFF]/.test(password))
    return 'Caracteres Unicode não são permitidos.';
  return '';
};

//
export const validateConfirmPassword = (
  confirmPassword: string,
  password: string,
): string => {
  if (!confirmPassword) return 'Por favor, confirme sua senha.';
  if (confirmPassword !== password) return 'As senhas não coincidem!';
  return '';
};

//
export const validateProfession = (
  profession: string,
  professionOptions: string[],
): string => {
  if (!profession) return 'Por favor, selecione sua profissão.';
  if (!professionOptions.includes(profession))
    return 'Caso sua profissão não esteja na lista, selecione "Outro".';
  return '';
};

//
export const validatePhone = (phone: string): string => {
  if (!phone) return 'Por favor, insira seu número de telefone.';
  if (!/^\(\d{2}\) \d{5}-\d{4}$/.test(phone))
    return 'O número de telefone está incompleto.';
  return '';
};

//
export const validateGender = (gender: string): string => {
  if (!gender) return 'Selecione seu gênero.';
  return '';
};

//
const today = new Date();
const maxAgeDate = new Date(today.getFullYear() - 116, 0, 2);
const minAgeDate = new Date(today.getFullYear() - 18, 11, 31);
export const validateBirthDate = (birthDate: Date): string => {
  if (!(birthDate instanceof Date) || isNaN(birthDate.getTime()))
    return 'Data inválida.';
  if (birthDate.getFullYear() > 2200) return 'Ano de nascimento inválido.';
  if (birthDate > today) return 'A data não pode estar no futuro.';
  if (birthDate > today) return 'A data não pode estar no futuro.';
  if (birthDate < maxAgeDate) return 'Insira um ano de nascimento realista.';
  if (birthDate > minAgeDate) return 'Você deve ter no mínimo 18 anos.';
  return '';
};
