import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { LoginForm, AuthResponse } from 'src/types/auth';
import { login } from 'src/api/auth';
import { useAuth } from 'src/hooks/useAuth';

/**
 * Logs in a user by sending their credentials to the API.
 * @returns {UseMutationResult<AuthResponse, Error, LoginForm>} The mutation result.
 * @example
 *  const {
 *    mutate: login,
 *    isPending,
 *    isError,
 *    error,
 *  } = useLogin();
 */
export const useLogin = (): UseMutationResult<
  AuthResponse,
  Error,
  LoginForm
> => {
  const { login: saveUser } = useAuth();

  return useMutation({
    mutationFn: login,
    onSuccess: (data) => {
      saveUser(data);
    },
  });
};
