import React from 'react';
import {
  Select,
  MenuItem,
  Box,
  FormControl,
  SelectChangeEvent,
} from '@mui/material';

interface SortByFilterProps {
  onSortByChange: (sortByOption: string, ascendingOption: boolean) => void;
}

const SortByFilter: React.FC<SortByFilterProps> = ({ onSortByChange }) => {
  const [sortByOptions, setSortByOptions] = React.useState({
    sortBy: 'publishDate',
    ascending: false,
  });

  const handleSortByChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    const newSortByOptions = {
      sortBy: value.includes('price') ? 'price' : 'publishDate',
      ascending: value === 'low_price' || value === 'oldest' ? true : false,
    };

    setSortByOptions(newSortByOptions);
    onSortByChange(newSortByOptions.sortBy, newSortByOptions.ascending);
  };

  return (
    <Box sx={{ display: 'flex', gap: 2 }}>
      <FormControl sx={{ minWidth: 150 }}>
        <Select
          className="h-8 lg:h-14"
          sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}
          value={
            sortByOptions.sortBy === 'price'
              ? sortByOptions.ascending
                ? 'low_price'
                : 'high_price'
              : sortByOptions.ascending
                ? 'oldest'
                : 'recent'
          }
          onChange={handleSortByChange}
        >
          <MenuItem value="low_price">Menor preço</MenuItem>
          <MenuItem value="high_price">Maior preço</MenuItem>
          <MenuItem value="recent">Mais recente</MenuItem>
          <MenuItem value="oldest">Mais antigo</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default SortByFilter;
