import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import { InternalLayout } from 'src/layouts/Internal';
import { DashboardPage } from 'src/pages/Dashboard/DashboardPage';
import { AuthProvider } from 'src/contexts/AuthContext';
import { LoginPage } from 'src/pages/auth/Login/LoginPage';
import { ForgotPasswordPage } from 'src/pages/auth/ForgotPassword/ForgotPasswordPage';
import responsiveTheme from 'src/theme/theme';
import { RegisterPage } from 'src/pages/auth/Register/RegisterPage';
import TermsOfUsePage from 'src/pages/auth/Terms/TermsOfUsePage';
import PrivacyPolicyPage from 'src/pages/auth/Terms/PrivacyPolicyPage';
import { ProtectedRoute } from 'src/components/ProtectedRoutes';
import ResetPasswordPage from 'src/pages/auth/ResetPassword/ResetPasswordPage';
import Marketplace from 'src/pages/Marketplace/MarketplacePage';

/**
 * Creates a React Query client with default options.
 * @type QueryClient
 */
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
/**
 * Creates a react-router-dom browser router with the routes of the application.
 * @type BrowserRouter
 */
const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/terms-of-use" element={<TermsOfUsePage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/reset-password" element={<ResetPasswordPage />} />
      <Route element={<ProtectedRoute />}>
        <Route element={<InternalLayout hasFooter />}>
          <Route path="/" element={<DashboardPage />} />
        </Route>
        <Route element={<InternalLayout hasFooter={false} />}>
          <Route path="/marketplace" element={<Marketplace />} />
        </Route>
      </Route>
    </>,
  ),
);
/**
 * The main application component.
 * @component
 */
export const App: React.FC = () => {
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={responsiveTheme}>
          <CssBaseline />
          <RouterProvider router={router} />
        </ThemeProvider>
      </QueryClientProvider>
    </AuthProvider>
  );
};
