import React from 'react';
import { Box, Typography } from '@mui/material';
import AuthButton from 'src/components/auth/AuthButton';
import useIsSmallScreen from 'src/theme/mobileTheme';

interface SuccessAlertProps {
  message: string;
  buttonText: string;
  onClose: () => void;
  buttonColor?: string;
  boxStyles?: React.CSSProperties;
}

const SuccessAlert: React.FC<SuccessAlertProps> = ({
  message,
  buttonText,
  onClose,
  boxStyles = {},
}) => {
  const isSmallScreen = useIsSmallScreen();
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        bgcolor: 'rgba(0, 0, 0, 0.5)',
        backdropFilter: 'blur(3px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        zIndex: 1000,
        paddingTop: '10vh',
      }}
    >
      <Box
        sx={{
          bgcolor: 'rgba(255, 255, 255, 0.8)',
          backdropFilter: 'blur(1px)',
          p: 3,
          borderRadius: 2,
          boxShadow: 3,
          textAlign: 'center',
          width: '90%',
          maxWidth: '400px',
          ...boxStyles,
        }}
      >
        <Box display="flex" justifyContent="center" mb={4}>
          <Box
            component="img"
            src="images/success-icon.png"
            alt="Logo"
            sx={{
              width: isSmallScreen ? 48 : 72,
              height: isSmallScreen ? 48 : 72,
            }}
          />
        </Box>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {message}
        </Typography>
        <AuthButton
          variant="contained"
          onClick={onClose}
          sx={{
            bgcolor: ' #1BA766',
            '&:hover': {
              bgcolor: ' #148e55',
              opacity: 0.9,
            },
          }}
        >
          {buttonText}
        </AuthButton>
      </Box>
    </Box>
  );
};

export default SuccessAlert;
