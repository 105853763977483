import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { ResetPasswordForm, AuthResponse } from 'src/types/auth';
import { resetPassword } from 'src/api/auth';

/**
 * Sends a request to the API to reset the user's password.
 * @returns {UseMutationResult<AuthResponse, Error, ResetPasswordForm>} The mutation result.
 * @example
 *  const {
 *    mutate: resetPassword,
 *    isPending,
 *    isError,
 *    error,
 *  } = useResetPassword();
 */
export const useResetPassword = (): UseMutationResult<
  AuthResponse,
  Error,
  ResetPasswordForm
> => {
  return useMutation({
    mutationFn: resetPassword,
  });
};
