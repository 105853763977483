import { Outlet } from 'react-router-dom';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { Box } from '@mui/material';

interface InternalLayoutProps {
  hasFooter?: boolean;
}
/**
 * @component
 * @returns {JSX.Element} The rendered layout with header, content, and footer.
 */
export const InternalLayout: React.FC<InternalLayoutProps> = ({
  hasFooter,
}) => {
  return (
    <Box className="flex min-h-screen flex-col font-sans">
      <Header />
      <Outlet />
      {hasFooter && <Footer />}
    </Box>
  );
};
