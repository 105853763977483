import { useState } from 'react';
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';

const conditions = [
  { display: 'Qualquer', value: '' },
  { display: 'Novo', value: 'NEW' },
  { display: 'Usado', value: 'USED' },
];

interface ConditionProps {
  onConditionChange: (ConditionOption: string) => void;
}

const ConditionFilter: React.FC<ConditionProps> = ({ onConditionChange }) => {
  const [selectedCondition, setSelectedCondition] = useState<string>('');

  const handleConditionClick = (condition: string) => {
    setSelectedCondition(condition);
    onConditionChange(condition);
  };

  return (
    <Box
      className="mb-2 rounded-lg border bg-white shadow-sm"
      sx={{ border: '1px solid #ccc' }}
    >
      <Typography variant="h6" className="p-1 pl-3 text-gray-500">
        Condição
      </Typography>
      <Divider sx={{ backgroundColor: '#ccc' }} />
      <List>
        {conditions.map((condition) => (
          <ListItem
            key={condition.value}
            onClick={() => handleConditionClick(condition.value)}
            className={`cursor-pointer p-2 ${
              selectedCondition === condition.value
                ? 'bg-gray-200 font-semibold'
                : 'hover:bg-gray-100'
            }`}
            sx={{
              mt: 0.2,
              height: '2rem',
              borderRadius: 1,
              '&:hover': {
                backgroundColor:
                  selectedCondition !== condition.value ? '#f0f0f0' : undefined,
              },
            }}
          >
            <ListItemText primary={condition.display} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default ConditionFilter;
