import { useContext } from 'react';
import { AuthContext } from 'src/contexts/AuthContext';

/**
 * useAuth
 *
 * Custom hook that provides access to the AuthContext.
 * @returns - The AuthContext.
 */
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
