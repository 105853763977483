import React, { useState, useEffect } from 'react';
import { Box, Typography, Snackbar, Alert } from '@mui/material';
import AuthButton from 'src/components/auth/AuthButton';
import AuthInput from 'src/components/auth/AuthInput';
import { useForgotPassword } from 'src/hooks/mutations/auth/useForgotPassword';
import SuccessAlert from 'src/components/SuccessAlert';
import useIsSmallScreen from 'src/theme/mobileTheme';
import { validateEmail } from 'src/utils/validations';

export const ForgotPasswordPage: React.FC = () => {
  const {
    mutate: forgotPassword,
    isPending,
    isError,
    error,
  } = useForgotPassword();
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>(
    'Por favor, insira seu e-mail.',
  );
  const [resendTimer, setResendTimer] = useState<number>(60);
  const [hasSent, setHasSent] = useState<boolean>(false);
  const [showValidateError, setShowValidateError] = useState<boolean>(false);
  const isSmallScreen = useIsSmallScreen();

  useEffect(() => {
    if (resendTimer > 0) {
      const timer = setTimeout(() => setResendTimer(resendTimer - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [resendTimer]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (emailError) {
      setShowValidateError(true);
      return;
    }

    forgotPassword(
      { email },
      {
        onSuccess: () => {
          setResendTimer(60);
          setShowSuccessAlert(true);
        },
      },
    );
  };
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      bgcolor="#f5f5f5"
      px={isSmallScreen ? 2 : 4}
    >
      <Box
        component="form"
        onSubmit={handleSubmit}
        bgcolor="white"
        p={4}
        sx={{
          borderRadius: 2,
          border: '1px solid #D1D5DB',
        }}
        width="100%"
        maxWidth="400px"
        aria-labelledby="forgot-password-title"
        noValidate
      >
        <Box display="flex" justifyContent="center" mb={3}>
          <Box
            component="img"
            src="/images/logo-todosganham.png"
            alt="Logo"
            sx={{ height: '64px' }}
          />
        </Box>
        <Typography variant="h5" textAlign="center" mb={2} gutterBottom>
          Esqueceu sua senha?
        </Typography>
        <Typography
          variant="body2"
          textAlign="center"
          color="textSecondary"
          mb={4}
        >
          Digite seu endereço de e-mail registrado no Todos Ganham para que
          possamos enviar um link para mudar sua senha.
        </Typography>
        <Typography
          variant="body2"
          component="label"
          htmlFor="email"
          sx={{ mb: 1, color: '#4B5563' }}
        >
          Seu e-mail usado no Todos Ganham:
        </Typography>
        <AuthInput
          name="email"
          type="text"
          inputMode="email"
          autoComplete="email"
          fullWidth
          variant="outlined"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setEmailError(validateEmail(e.target.value));
          }}
          required
          error={!!emailError && showValidateError}
          helperText={emailError && showValidateError ? emailError : ''}
          aria-describedby="email-helper-text"
          sx={{ mb: 3 }}
        />
        {isError && error && (
          <Snackbar
            open={true}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert severity="error" sx={{ width: '100%' }}>
              {error.message || 'Ocorreu um erro inesperado'}
            </Alert>
          </Snackbar>
        )}
        <AuthButton
          type="submit"
          disabled={
            isPending ||
            (hasSent && resendTimer > 0) ||
            (showValidateError && !!emailError)
          }
          fullWidth
        >
          {isPending
            ? hasSent
              ? 'Reenviando...'
              : 'Enviando...'
            : hasSent
              ? 'Reenviar'
              : 'Enviar'}
        </AuthButton>

        {hasSent && (
          <Typography
            variant="body2"
            textAlign="center"
            color="textSecondary"
            mt={2}
          >
            Pode reenviar e-mail em 00:
            {resendTimer < 10 ? `0${resendTimer}` : resendTimer}
          </Typography>
        )}
      </Box>
      {showSuccessAlert && (
        <SuccessAlert
          message={
            hasSent
              ? 'O link foi reenviado. Caso não encontre o e-mail, verifique a caixa de spam ou lixo eletrônico. Tenha certeza que seu e-mail está correto.'
              : 'Enviamos um link para redefinir sua senha. Verifique sua caixa de entrada e clique no link para continuar.'
          }
          buttonText="Ok"
          onClose={() => {
            setShowSuccessAlert(false);
            setHasSent(true);
          }}
          buttonColor="rgb(19, 132, 79)"
        />
      )}
    </Box>
  );
};

export default ForgotPasswordPage;
