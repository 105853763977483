import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  IconButton,
  Alert,
  Snackbar,
  Link,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import AuthInput from 'src/components/auth/AuthInput';
import AuthButton from 'src/components/auth/AuthButton';
import { useLogin } from 'src/hooks/mutations/auth/useLogin';
import useIsSmallScreen from 'src/theme/mobileTheme';
import { validateEmail } from 'src/utils/validations';

export const LoginPage: React.FC = () => {
  const isSmallScreen = useIsSmallScreen();
  const navigate = useNavigate();
  const { mutate: login, isPending, isError, error } = useLogin();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false,
  });

  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState<string>(
    'Por favor, insira sua senha.',
  );
  const [emailError, setEmailError] = useState<string>(
    'Por favor, insira seu e-mail.',
  );
  const [showValidateError, setShowValidateError] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const validatePassword = (password: string) => {
    if (!password) {
      setPasswordError('Por favor, insira sua senha.');
      return;
    }
    setPasswordError('');
    return;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (emailError || !formData.password) {
      setShowValidateError(true);
      return;
    }

    login(formData, {
      onSuccess: () => {
        navigate('/');
      },
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        padding: isSmallScreen ? 2 : 4,
        bgcolor: ' #F8F8F8',
      }}
    >
      <Box
        borderRadius={2}
        sx={{
          width: '100%',
          maxWidth: 400,
          p: 4,
          border: '1px solid #D1D5DB',
          bgcolor: '#FFFFFF',
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          mb={1}
          tabIndex={0}
          aria-label="Logo do Todos Ganham"
        >
          <Box
            component="img"
            src="/images/logo-todosganham.png"
            alt="Logo"
            sx={{ width: 64, height: 64 }}
          />
        </Box>
        <Typography variant="h5" align="center" sx={{ mb: 3 }}>
          Entrar
        </Typography>

        <Box component="form" onSubmit={handleSubmit}>
          <Typography variant="body2" color="textSecondary">
            Seu endereço de e-mail
          </Typography>
          <AuthInput
            name="email"
            type="text"
            inputMode="email"
            autoComplete="email"
            value={formData.email}
            onChange={(e) => {
              setFormData({ ...formData, email: e.target.value });
              setEmailError(validateEmail(e.target.value));
            }}
            fullWidth
            variant="outlined"
            error={!!emailError && showValidateError}
            helperText={emailError && showValidateError ? emailError : ''}
          />

          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mt: 1,
            }}
          >
            <Typography variant="body2" color="textSecondary">
              Senha
            </Typography>
            <Typography
              variant="body2"
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: '#715D5DCC',
                cursor: 'pointer',
              }}
              onClick={() => setShowPassword(!showPassword)}
            >
              <IconButton
                size="small"
                sx={{ color: '#715D5DCC', mr: 0 }}
                edge="end"
                aria-label={showPassword ? 'Ocultar senha' : 'Mostrar senha'}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
              {showPassword ? 'Ocultar' : 'Mostrar'}
            </Typography>
          </Box>

          <AuthInput
            name="password"
            type={showPassword ? 'text' : 'password'}
            value={formData.password}
            onChange={(e) => {
              setFormData({ ...formData, password: e.target.value });
              validatePassword(e.target.value);
            }}
            fullWidth
            variant="outlined"
            sx={{ mt: 0 }}
            error={!!passwordError && showValidateError}
            helperText={passwordError && showValidateError ? passwordError : ''}
          />

          <FormControlLabel
            control={
              <Checkbox
                name="remember"
                checked={formData.remember}
                onChange={handleChange}
                sx={{
                  '&.Mui-checked': {
                    color: 'black',
                  },
                }}
              />
            }
            label={
              <Typography variant="body2" color="textSecondary">
                Lembre de mim
              </Typography>
            }
            sx={{ mt: 1, mb: 2 }}
          />

          <AuthButton
            type="submit"
            disabled={
              isPending ||
              (!!emailError && showValidateError) ||
              (!!passwordError && showValidateError)
            }
            sx={{
              cursor: isPending ? 'not-allowed' : 'pointer',
            }}
            fullWidth
          >
            {isPending ? 'Entrando...' : 'Entrar'}
          </AuthButton>
        </Box>

        <Typography variant="body2" align="center" sx={{ mt: 3 }}>
          Não tem uma conta?{' '}
          <Link href="/register" underline="always" sx={{ color: '#000' }}>
            Cadastre-se
          </Link>
        </Typography>
        <Typography variant="body2" align="center" sx={{ mt: 1 }}>
          <Link
            href="/forgot-password"
            underline="always"
            sx={{
              color: '#000',
            }}
          >
            Esqueceu sua senha?
          </Link>
        </Typography>
      </Box>
      {isError && error && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert severity="error" sx={{ width: '100%' }}>
            {error.message || 'Erro desconhecido.'}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default LoginPage;
