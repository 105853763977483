import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { useAuth } from 'src/hooks/useAuth';
import { Link } from 'react-router-dom';
//import { SearchInput } from './SearchInput';

const navLinks = [
  { href: '#cooperativa', label: 'Cooperativa' },
  { href: '#planejamento', label: 'Planejamento' },
  { href: 'marketplace', label: 'Mercado' },
  { href: '#contato', label: 'Contato' },
];

export const Header: React.FC = () => {
  const { user } = useAuth();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setDrawerOpen(open);
    };

  const drawerList = () => (
    <Box
      sx={{ width: 260 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Link
        to="/"
        style={{ display: 'flex', alignItems: 'center', padding: 12 }}
      >
        <Box
          component="img"
          src="/images/logo-todosganham.png"
          alt="Todos Ganham"
          className="h-10 pr-2"
        />
        <Typography component="span" variant="h6" className="text-gray-800">
          Todos Ganham
        </Typography>
      </Link>
      <List>
        {navLinks.map((link, index) => (
          <React.Fragment key={link.href}>
            {index < navLinks.length && (
              <Divider sx={{ backgroundColor: '#ccc' }} />
            )}
            <ListItem
              component="a"
              href={link.href}
              sx={{ fontSize: '0.75rem' }}
            >
              <ListItemText primary={link.label} />
            </ListItem>
          </React.Fragment>
        ))}
      </List>
    </Box>
  );

  return (
    <AppBar
      position="sticky"
      color="transparent"
      elevation={0}
      className="border-b border-gray-100 lg:pl-8 lg:pr-8"
      sx={{
        bgcolor: 'rgba(253, 253, 253, 0.9)',
        backdropFilter: 'blur(4px)',
        borderBottom: '0.5px solid #ccc',
        zIndex: 99,
        height: { xs: '56px', sm: '64px', md: '72px' },
      }}
    >
      <Toolbar
        className="flex items-center justify-between"
        sx={{
          minHeight: { xs: '56px', sm: '64px', md: '72px' },
        }}
      >
        <Box className="flex items-center gap-2">
          <Box className="flex items-center gap-6 md:hidden">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Link to="/">
            <Box
              component="img"
              src="/images/logo-todosganham.png"
              alt="Todos Ganham"
              sx={{
                height: { xs: '32px', sm: '40px', md: '48px', lg: '56px' },
                minWidth: { xs: '32px', sm: '40px', md: '48px', lg: '56px' },
              }}
            />
          </Link>
        </Box>

        <Box className="hidden items-center gap-6 md:flex">
          {navLinks.map((link) => (
            <Typography
              key={link.href}
              variant="body1"
              component="a"
              href={link.href}
              className="cursor-pointer text-gray-800 hover:text-gray-600"
              sx={{
                fontSize: {
                  xs: '0.75rem',
                  sm: '0.875rem',
                  md: '1rem',
                  lg: '1.125rem',
                },
              }}
            >
              {link.label}
            </Typography>
          ))}
        </Box>

        <Box className="flex items-center gap-4">{/*<SearchInput />*/}</Box>

        <Box className="flex items-center gap-4">
          <Box className="text-right">
            <Typography
              sx={{
                fontSize: {
                  xs: '0.625rem',
                  sm: '0.75rem',
                  md: '0.875rem',
                  lg: '1rem',
                },
              }}
              className="text-gray-800"
            >
              {user?.firstName + ' ' + user?.lastName || 'Usuário'}
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: '0.625rem',
                  sm: '0.75rem',
                  md: '0.875rem',
                  lg: '1rem',
                },
              }}
              className="text-gray-500"
            >
              {user?.email ?? 'usuario@gmail.com'}
            </Typography>
          </Box>
        </Box>
      </Toolbar>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        {drawerList()}
      </Drawer>
    </AppBar>
  );
};
