import React, { createContext, useState } from 'react';
import { AuthResponse } from 'src/types/auth';

interface AuthContextType {
  user: AuthResponse['user'] | null;
  token: string | null;
  login: (data: AuthResponse) => void;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextType | null>(null);

/**
 * AuthProvider
 *
 * Provides authentication context to child components
 * Manages user authentication state and provides login and logout functions.
 * @param children The child components that will have access to the context.
 * @returns component.
 */

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [token, setToken] = useState<string | null>(
    localStorage.getItem('token') || null,
  );
  const [user, setUser] = useState<AuthResponse['user'] | null>(
    JSON.parse(localStorage.getItem('user') || 'null'),
  );

  /**
   * Logs in the user by storing authentication data.
   * @param {AuthResponse} data - The authentication response containing the token and user information.
   */

  const login = (data: AuthResponse) => {
    setToken(data.token);
    setUser(data.user);
    localStorage.setItem('token', data.token);
    localStorage.setItem('user', JSON.stringify(data.user));
  };

  const logout = () => {
    setToken(null);
    setUser(null);
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  };

  return (
    <AuthContext.Provider value={{ token, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
