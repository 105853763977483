import {
  Container,
  Typography,
  Link,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';

const TermsOfUsePage = () => {
  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom>
        Termos de Uso
      </Typography>

      <Typography variant="h6" gutterBottom>
        1. Termos
      </Typography>
      <Typography variant="body1" gutterBottom>
        Ao acessar o site{' '}
        <Link href="https://www.todosganham.agr.br">Todos Ganham</Link>, você
        concorda em cumprir estes termos de serviço, todas as leis e
        regulamentos aplicáveis, e aceita a responsabilidade pelo cumprimento de
        todas as leis locais. Se não concordar com algum desses termos, está
        proibido de usar ou acessar este site.
      </Typography>

      <Typography variant="h6" gutterBottom>
        2. Uso de Licença
      </Typography>
      <Typography variant="body1" gutterBottom>
        É concedida permissão para baixar temporariamente uma cópia dos
        materiais no site Todos Ganham, apenas para visualização pessoal e não
        comercial. Sob esta licença, você não pode:
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="Modificar ou copiar os materiais." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Usar os materiais para qualquer finalidade comercial ou exibição pública." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Tentar descompilar ou fazer engenharia reversa de qualquer software do site." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Remover direitos autorais ou outras notações de propriedade." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Transferir os materiais para outra pessoa ou espelhar os materiais em outro servidor." />
        </ListItem>
      </List>

      <Typography variant="h6" gutterBottom>
        3. Isenção de Responsabilidade
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="Os materiais são fornecidos 'como estão', sem garantias de qualquer tipo." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Todos Ganham não garante a precisão ou confiabilidade dos materiais no site." />
        </ListItem>
      </List>

      <Typography variant="h6" gutterBottom>
        4. Limitações
      </Typography>
      <Typography variant="body1" gutterBottom>
        Em nenhum caso o Todos Ganham ou seus fornecedores serão responsáveis
        por quaisquer danos decorrentes do uso ou incapacidade de usar os
        materiais no site.
      </Typography>

      <Typography variant="h6" gutterBottom>
        5. Precisão dos Materiais
      </Typography>
      <Typography variant="body1" gutterBottom>
        Os materiais exibidos no site podem conter erros técnicos, tipográficos
        ou fotográficos. Todos Ganham pode alterar os materiais a qualquer
        momento, sem aviso prévio, mas não se compromete a atualizá-los.
      </Typography>

      <Typography variant="h6" gutterBottom>
        6. Links
      </Typography>
      <Typography variant="body1" gutterBottom>
        Todos Ganham não analisa todos os sites vinculados e não se
        responsabiliza pelo conteúdo deles. O uso de qualquer site vinculado é
        por conta e risco do usuário.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Modificações
      </Typography>
      <Typography variant="body1" gutterBottom>
        Todos Ganham pode revisar estes termos a qualquer momento, sem aviso
        prévio. Ao usar o site, você concorda em seguir a versão atual dos
        termos de serviço.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Lei Aplicável
      </Typography>
      <Typography variant="body1" gutterBottom>
        Estes termos são regidos pelas leis aplicáveis ao Todos Ganham, e você
        se submete à jurisdição exclusiva dos tribunais competentes.
      </Typography>
    </Container>
  );
};

export default TermsOfUsePage;
