import React from 'react';
import {
  CardContent,
  CardMedia,
  Box,
  Typography,
  IconButton,
} from '@mui/material';
import { FavoriteBorder } from '@mui/icons-material';

interface AdCardProps {
  _id: string;
  title: string;
  price: number;
}

const AdCard: React.FC<AdCardProps> = ({ _id, title, price }) => {
  return (
    <Box
      className="relative cursor-pointer rounded-lg bg-white transition hover:shadow-md"
      sx={{
        boxShadow: 'none',
        p: { xs: 0.5, sm: 1 },
        width: '100%',
        minWidth: { xs: 140, sm: 160, md: 200, lg: 240, xl: 250 },
        height: { xs: 210, sm: 240, md: 260, lg: 340, xl: 420 },
      }}
    >
      <CardMedia
        component="img"
        image={'/images/example-image.png'}
        alt={title}
        className="rounded-lg"
        sx={{
          height: { xs: 150, sm: 160, md: 180, lg: 240, xl: 320 },
          objectFit: 'cover',
          width: '100%',
        }}
      />

      <Box className="absolute right-2 top-2 flex flex-col gap-2">
        <IconButton className="rounded-full bg-white p-1" size="small">
          <FavoriteBorder />
        </IconButton>
      </Box>

      <CardContent
        sx={{
          paddingX: { xs: 1, sm: 1.5 },
        }}
      >
        <Typography
          className="font-bold"
          sx={{
            fontSize: {
              xs: '0.75rem',
              sm: '0.875rem',
              md: '1rem',
              lg: '1.25rem',
            },
          }}
          noWrap
        >
          {title}
        </Typography>
        <Typography
          color="error"
          sx={{
            fontSize: {
              xs: '0.7rem',
              sm: '0.875rem',
              md: '1rem',
              lg: '1.2rem',
            },
          }}
        >
          R$ {price.toFixed(2)}
        </Typography>
      </CardContent>
    </Box>
  );
};

export default AdCard;
