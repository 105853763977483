import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { ForgotPasswordForm, AuthResponse } from 'src/types/auth';
import { forgotPassword } from 'src/api/auth';

/**
 * Sends a request to the API to reset the user's password.
 * @returns {UseMutationResult<AuthResponse, Error, ForgotPasswordForm>} The mutation result.
 * @example
 *  const {
 *    mutate: forgotPassword,
 *    isPending,
 *    isError,
 *    error,
 *  } = useForgotPassword();
 */
export const useForgotPassword = (): UseMutationResult<
  AuthResponse,
  Error,
  ForgotPasswordForm
> => {
  return useMutation({
    mutationFn: forgotPassword,
  });
};
