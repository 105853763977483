import React from 'react';
import Button from '@mui/material/Button';

interface AuthButtonProps {
  type?: 'button' | 'submit' | 'reset';
  fullWidth?: boolean;
  variant?: 'text' | 'outlined' | 'contained';
  sx?: object;
  className?: string;
  onClick?: () => void;
  children: React.ReactNode;
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  disabled?: boolean;
  tabIndex?: number;
}
const AuthButton: React.FC<AuthButtonProps> = ({
  type = 'button',
  fullWidth = true,
  variant = 'contained',
  sx = {},
  onClick,
  children,
  disabled = false,
}) => {
  return (
    <Button
      type={type}
      fullWidth={fullWidth}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      sx={{
        borderRadius: '999px',
        backgroundColor: 'black',
        color: 'white',
        padding: '8px',
        '&:hover': {
          backgroundColor: 'gray.800',
        },
        ...sx,
      }}
    >
      {children}
    </Button>
  );
};

export default AuthButton;
