import React, { useState, useEffect } from 'react';
import { getAds } from 'src/api/ads';
import VirtualizedList from 'src/components/marketplace/VirtualizedList';
import { AdCardForm, AdFiltersForm } from 'src/types/ads';
import PeriodFilter from 'src/components/marketplace/filters/PeriodFilter';
import {
  Box,
  IconButton,
  Typography,
  Drawer,
  Divider,
  Button,
} from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import { useAuth } from 'src/hooks/useAuth';
// import CategoryFilter from 'src/components/marketplace/filters/CategoryFilter';
import SortByFilter from 'src/components/marketplace/filters/SortByFilter';
// import LocationFilter from 'src/components/marketplace/filters/LocationFilter';
import PriceFilter from 'src/components/marketplace/filters/PriceFilter';
import ConditionFilter from 'src/components/marketplace/filters/ConditionFilter';

const Marketplace: React.FC = () => {
  const { token } = useAuth();
  const [ads, setAds] = useState<AdCardForm[]>([]);
  const [filters, setFilters] = useState<AdFiltersForm>({});
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setDrawerOpen(open);
    };

  useEffect(() => {
    if (!token) return;

    const handler = setTimeout(() => {
      getAds(filters, token)
        .then(setAds)
        .catch((error) => console.error('Falha ao buscar anúncios:', error));
    }, 300);

    return () => clearTimeout(handler);
  }, [filters, token]);

  const drawerFilters = () => (
    <Box
      sx={{ width: 260, padding: 2 }}
      role="presentation"
      onKeyDown={toggleDrawer(false)}
    >
      <Typography variant="h6" sx={{ fontWeight: 450, pb: 2 }}>
        Filtros <TuneIcon />
      </Typography>
      <Box
        className="mb-2 rounded-lg border bg-white"
        sx={{ border: '1px solid #ccc' }}
      >
        <Typography variant="h6" className="p-1 pl-3 text-gray-500">
          Ordenar por
        </Typography>
        <Divider sx={{ backgroundColor: '#ccc' }} />
        <Box className="m-1 flex flex-col gap-1" sx={{ alignItems: 'center' }}>
          <PeriodFilter
            onPeriodFilterChange={(options) => {
              setFilters((prev) => ({
                ...prev,
                since: options.toISOString(),
              }));
            }}
          />
          <SortByFilter
            onSortByChange={(sortOption, ascendingOption) => {
              setFilters((prev) => ({
                ...prev,
                sort: sortOption,
                ascending: ascendingOption,
              }));
            }}
          />
        </Box>
      </Box>
      {/*<CategoryFilter
        onCategoryChange={(category) => {
          setFilters((prev) => ({
            ...prev,
            category: category,
          }));
        }}
      />
      <LocationFilter
        onStateChange={(state) => {
          setFilters((prev) => ({
            ...prev,
            location: { state: state },
          }));
        }}
      />*/}
      <ConditionFilter
        onConditionChange={(condition) => {
          setFilters((prev) => ({
            ...prev,
            condition: condition as 'NEW' | 'USED' | '' | undefined,
          }));
        }}
      />
      <PriceFilter
        onPriceChange={(priceRange) => {
          setFilters((prev) => ({
            ...prev,
            priceRange: priceRange,
          }));
        }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Button
          variant="contained"
          onClick={() => window.location.reload()}
          sx={{
            color: 'white',
            backgroundColor: '#FFA932',
          }}
        >
          Limpar Filtros
        </Button>
      </Box>
    </Box>
  );

  return (
    <Box
      className="pr-2 lg:pr-12"
      sx={{
        display: 'flex',
        backgroundColor: 'transparent',
      }}
    >
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          p: 1,
          pb: 10,
          borderRight: '0.5px solid #ccc',
          height: '100vh',
          overflowY: 'auto',
        }}
        className="hidden md:block md:w-1/4 lg:mr-6"
      >
        {/*<CategoryFilter
          onCategoryChange={(category) => {
            setFilters((prev) => ({
              ...prev,
              category: category,
            }));
          }}
        />
        <LocationFilter
          onStateChange={(state) => {
            setFilters((prev) => ({
              ...prev,
              location: { state: state },
            }));
          }}
        />*/}
        <ConditionFilter
          onConditionChange={(condition) => {
            setFilters((prev) => ({
              ...prev,
              condition: condition as 'NEW' | 'USED' | '' | undefined,
            }));
          }}
        />
        <PriceFilter
          onPriceChange={(priceRange) => {
            setFilters((prev) => ({
              ...prev,
              priceRange: priceRange,
            }));
          }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Button
            variant="contained"
            onClick={() => window.location.reload()}
            sx={{
              color: 'white',
              backgroundColor: '#FFA932',
            }}
          >
            Limpar Filtros
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          height: '100vh',
          overflowY: 'auto',
          gap: 2,
          pb: 10,
        }}
        className="w-1/1 ml-1 sm:ml-4 md:w-3/4 md:pt-4 lg:pt-0 "
      >
        <Box
          className="flex w-full flex-row md:w-3/4"
          sx={{
            position: 'fixed',
            top: { xs: '56px', sm: '64px', md: '72px' },
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            right: 0,
            gap: 2,
            p: 1,
            zIndex: 98,
            backgroundColor: 'rgba(248, 248, 248, 0.9)',
            backdropFilter: 'blur(4px)',
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '1.25rem', sm: '2rem', lg: '2.5rem' },
              fontWeight: 550,
            }}
          >
            Produtos à venda!
          </Typography>
          <Box
            className="m-1 ml-0 flex hidden gap-1 sm:ml-1 md:flex md:flex-col lg:flex-row lg:gap-4"
            sx={{ alignItems: { xs: 'center', sm: 'right' } }}
          >
            <PeriodFilter
              onPeriodFilterChange={(options) => {
                setFilters((prev) => ({
                  ...prev,
                  since: options.toISOString(),
                }));
              }}
            />
            <SortByFilter
              onSortByChange={(sortOption, ascendingOption) => {
                setFilters((prev) => ({
                  ...prev,
                  sort: sortOption,
                  ascending: ascendingOption,
                }));
              }}
            />
          </Box>
          <Box
            className="block md:hidden"
            sx={{
              borderRadius: 1,
              backgroundColor: ' #eeeeee',
              '&:hover': {
                backgroundColor: ' #dddddd',
              },
              '&:active': {
                backgroundColor: ' #cccccc',
              },
            }}
          >
            <IconButton
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: { xs: '100%', sm: 'auto' },
              }}
            >
              <Typography
                color="primary"
                sx={{
                  fontWeight: 450,
                  fontSize: { xs: '0.875rem', sm: '1.175rem' },
                }}
              >
                Filtros
              </Typography>
              <TuneIcon
                color="primary"
                sx={{
                  fontSize: { xs: '1.25rem', sm: '1.5rem', md: '2rem' },
                }}
              />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ pt: { xs: '56px', sm: '64px', md: '72px' } }}>
          <VirtualizedList ads={ads} />
        </Box>
      </Box>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        {drawerFilters()}
      </Drawer>
    </Box>
  );
};

export default Marketplace;
