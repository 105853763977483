import React from 'react';
import { Container, Typography, Link, List, ListItem } from '@mui/material';

const PrivacyPolicyPage = () => {
  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" color="textPrimary" gutterBottom>
        Política de Privacidade
      </Typography>
      <Typography variant="body1" color="textSecondary">
        A sua privacidade é importante para nós. É política do{' '}
        <Link href="https://www.todosganham.agr.br">Todos Ganham</Link>{' '}
        respeitar a sua privacidade em relação a qualquer informação sua que
        possamos coletar no site, e outros sites que possuímos e operamos.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        Solicitamos informações pessoais apenas quando realmente precisamos
        delas para lhe fornecer um serviço. Fazemo-lo por meios justos e legais,
        com o seu conhecimento e consentimento. Também informamos por que
        estamos coletando e como será usado.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        Apenas retemos as informações coletadas pelo tempo necessário para
        fornecer o serviço solicitado. Quando armazenamos dados, protegemos
        dentro de meios comercialmente aceitáveis para evitar perdas e roubos,
        bem como acesso, divulgação, cópia, uso ou modificação não autorizados.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        Não compartilhamos informações de identificação pessoal publicamente ou
        com terceiros, exceto quando exigido por lei.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        O nosso site pode ter links para sites externos que não são operados por
        nós. Esteja ciente de que não temos controle sobre o conteúdo e práticas
        desses sites e não podemos aceitar responsabilidade por suas respectivas
        políticas de privacidade.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        Você é livre para recusar a nossa solicitação de informações pessoais,
        entendendo que talvez não possamos fornecer alguns dos serviços
        desejados.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        O uso continuado de nosso site será considerado como aceitação de nossas
        práticas em torno de privacidade e informações pessoais. Se você tiver
        alguma dúvida sobre como lidamos com dados do usuário e informações
        pessoais, entre em contacto connosco.
      </Typography>

      <Typography variant="h6" color="textPrimary" gutterBottom>
        Compromisso do Usuário
      </Typography>
      <Typography variant="body1" color="textSecondary">
        O usuário se compromete a fazer uso adequado dos conteúdos e da
        informação que o Todos Ganham oferece no site e com caráter enunciativo,
        mas não limitativo:
      </Typography>
      <List>
        <ListItem>
          <Typography variant="body1" color="textSecondary">
            A) Não se envolver em atividades que sejam ilegais ou contrárias à
            boa fé e à ordem pública;
          </Typography>
        </ListItem>
        <ListItem>
          <Typography variant="body1" color="textSecondary">
            B) Não difundir propaganda ou conteúdo de natureza racista,
            xenofóbica, ou azar, qualquer tipo de pornografia ilegal, de
            apologia ao terrorismo ou contra os direitos humanos;
          </Typography>
        </ListItem>
        <ListItem>
          <Typography variant="body1" color="textSecondary">
            C) Não causar danos aos sistemas físicos (hardwares) e lógicos
            (softwares) do Todos Ganham, de seus fornecedores ou terceiros, para
            introduzir ou disseminar vírus informáticos ou quaisquer outros
            sistemas de hardware ou software que sejam capazes de causar danos
            anteriormente mencionados.
          </Typography>
        </ListItem>
      </List>

      <Typography variant="h6" color="textPrimary" gutterBottom>
        Mais Informações
      </Typography>
      <Typography variant="body1" color="textSecondary">
        Esperemos que esteja esclarecido e, como mencionado anteriormente, se
        houver algo que você não tem certeza se precisa ou não, geralmente é
        mais seguro deixar os cookies ativados, caso interaja com um dos
        recursos que você usa em nosso site.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        Esta política é efetiva a partir de 7 de fevereiro de 2025.
      </Typography>
    </Container>
  );
};

export default PrivacyPolicyPage;
