import { AdFiltersForm } from 'src/types/ads';

const API_URL = process.env.REACT_APP_PUBLIC_API_BASE_URL;

const getDefaultSinceDate = (days: number) => {
  const date = new Date();
  date.setDate(date.getDate() - days);
  return date.toISOString();
};

export const getAds = async (filters: AdFiltersForm, token: string) => {
  const queryParams = new URLSearchParams();
  if (filters.category?.length)
    queryParams.append('category', filters.category);
  if (filters.priceRange) {
    if (filters.priceRange[0] !== null)
      queryParams.append('minPrice', filters.priceRange[0].toString());
    if (filters.priceRange[1] !== null)
      queryParams.append('maxPrice', filters.priceRange[1].toString());
  }
  if (filters.location?.state)
    queryParams.append('state', filters.location.state);
  if (filters.location?.city) queryParams.append('city', filters.location.city);
  if (filters.since) queryParams.append('since', filters.since);
  else queryParams.append('since', getDefaultSinceDate(30));
  queryParams.append('until', new Date().toISOString());
  queryParams.append('sortBy', filters.sortBy || 'publishDate');
  queryParams.append('ascending', filters.ascending?.toString() || 'false');
  if (filters.condition) queryParams.append('condition', filters.condition);

  console.log('queryParams:', queryParams.toString()); // Debug

  const res = await fetch(`${API_URL}/ads?${queryParams.toString()}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!res.ok) {
    switch (res.status) {
      case 401:
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        throw new Error(`Não autorizado: ${res.statusText}`);
      default:
        throw new Error(`Erro ao buscar anúncios: ${res.statusText}`);
    }
  }

  return res.json();
};
