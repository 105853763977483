import React from 'react';
import {
  Container,
  Typography,
  Box,
  Link,
  IconButton,
  Divider,
  Stack,
} from '@mui/material';
import clsx from 'clsx';
import { Facebook, Twitter, Instagram } from '@mui/icons-material';

interface Props {
  className?: string;
}

export const Footer: React.FC<Props> = ({ className }) => {
  return (
    <Box
      component="footer"
      className={clsx(className)}
      style={{
        padding: '48px 0',
        marginTop: 'auto',
        backgroundColor: '#f5f5f5',
      }}
    >
      <Container maxWidth="lg">
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={4}>
          <Box flex={1}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                fontSize: { xs: '1rem', lg: '1.25rem' },
              }}
            >
              Produto
            </Typography>
            <Link
              href="#"
              color="inherit"
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
                fontSize: { xs: '0.750rem', md: '0.875rem', lg: '1rem' },
                display: 'block',
              }}
            >
              Preços
            </Link>
            <Link
              href="#"
              color="inherit"
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
                fontSize: { xs: '0.750rem', md: '0.875rem', lg: '1rem' },
                display: 'block',
              }}
            >
              Soluções
            </Link>
            <Link
              href="#"
              color="inherit"
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
                fontSize: { xs: '0.750rem', md: '0.875rem', lg: '1rem' },
                display: 'block',
              }}
            >
              Cooperatividade
            </Link>
            <Link
              href="#"
              color="inherit"
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
                fontSize: { xs: '0.750rem', md: '0.875rem', lg: '1rem' },
              }}
            >
              Planos de equipe
            </Link>
          </Box>
          <Box flex={1}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                fontSize: { xs: '1rem', lg: '1.25rem' },
              }}
            >
              Sobre Nós
            </Typography>
            <Link
              href="#"
              color="inherit"
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
                fontSize: { xs: '0.750rem', md: '0.875rem', lg: '1rem' },
                display: 'block',
              }}
            >
              Sobre
            </Link>
            <Link
              href="#"
              color="inherit"
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
                fontSize: { xs: '0.750rem', md: '0.875rem', lg: '1rem' },
                display: 'block',
              }}
            >
              Marca
            </Link>
            <Link
              href="#"
              color="inherit"
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
                fontSize: { xs: '0.750rem', md: '0.875rem', lg: '1rem' },
                display: 'block',
              }}
            >
              Notícias
            </Link>
            <Link
              href="#"
              color="inherit"
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
                fontSize: { xs: '0.750rem', md: '0.875rem', lg: '1rem' },
                display: 'block',
              }}
            >
              Parcerias
            </Link>
            <Link
              href="#"
              color="inherit"
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
                fontSize: { xs: '0.750rem', md: '0.875rem', lg: '1rem' },
              }}
            >
              Afiliados
            </Link>
          </Box>
          <Box flex={1}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                fontSize: { xs: '1rem', lg: '1.25rem' },
              }}
            >
              Ajuda e Suporte
            </Typography>
            <Link
              href="#"
              color="inherit"
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
                fontSize: { xs: '0.750rem', md: '0.875rem', lg: '1rem' },
                display: 'block',
              }}
            >
              Central de ajuda
            </Link>
            <Link
              href="#"
              color="inherit"
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
                fontSize: { xs: '0.750rem', md: '0.875rem', lg: '1rem' },
                display: 'block',
              }}
            >
              Contate-nos
            </Link>
            <Link
              href="#"
              color="inherit"
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
                fontSize: { xs: '0.750rem', md: '0.875rem', lg: '1rem' },
                display: 'block',
              }}
            >
              Termos e Privacidade
            </Link>
            <Link
              href="#"
              color="inherit"
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
                fontSize: { xs: '0.750rem', md: '0.875rem', lg: '1rem' },
              }}
            >
              Informação de segurança
            </Link>
          </Box>
          <Box flex={1}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                fontSize: { xs: '1rem', lg: '1.25rem' },
              }}
            >
              Comunidade
            </Typography>
            <Link
              href="#"
              color="inherit"
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
                fontSize: { xs: '0.750rem', md: '0.875rem', lg: '1rem' },
                display: 'block',
              }}
            >
              Agências
            </Link>
            <Link
              href="#"
              color="inherit"
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
                fontSize: { xs: '0.750rem', md: '0.875rem', lg: '1rem' },
                display: 'block',
              }}
            >
              Vendedores
            </Link>
            <Link
              href="#"
              color="inherit"
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
                fontSize: { xs: '0.750rem', md: '0.875rem', lg: '1rem' },
              }}
            >
              Cooperativas
            </Link>
          </Box>
        </Stack>
        <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />

        <Box
          mt={5}
          display="flex"
          justifyContent="space-between"
          gap={2}
          sx={{
            mt: 5,
            fontSize: { xs: '0.750rem', md: '0.875rem', lg: '1rem' },
          }}
        >
          <Box>
            @TodosGanham
            {' | '}
            <Link
              href="#"
              color="inherit"
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              Ajuda
            </Link>
            {' | '}
            <Link
              href="#"
              color="inherit"
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              Privacidade
            </Link>
            {' | '}
            <Link
              href="#"
              color="inherit"
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              Termos
            </Link>
          </Box>
          <Box>
            <IconButton href="#" sx={{ p: 0, mr: 1 }}>
              <Facebook fontSize="small" />
            </IconButton>
            <IconButton href="#" sx={{ p: 0, mr: 1 }}>
              <Twitter fontSize="small" />
            </IconButton>
            <IconButton href="#" sx={{ p: 0, mr: 1 }}>
              <Instagram fontSize="small" />
            </IconButton>
            <Typography component="span" sx={{ fontSize: '0.875rem' }}>
              Português (Brasil)
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
