import { Box, Button, Typography } from '@mui/material';
import { useAuth } from 'src/hooks/useAuth';
/**
 * DashboardPage component
 *
 * Displays a list of supply types fetched from the useSupplyTypes hook
 * and provides a logout button that triggers the logout function from the useAuth hook.
 *
 * @returns {JSX.Element} The rendered DashboardPage component
 */
export const DashboardPage: React.FC = () => {
  const { logout } = useAuth();

  return (
    <Box className="mb-10 p-4 text-blue-800">
      <Typography className="mb-4" variant="h3">
        Pagina inicial
      </Typography>
      <Button
        onClick={logout}
        variant="contained"
        color="error"
        sx={{ marginTop: 2 }}
      >
        Sair
      </Button>
    </Box>
  );
};
