import React, { useRef } from 'react';
import { FixedSizeGrid as Grid } from 'react-window';
import AdCard from './AdCard';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { AdCardForm } from 'src/types/ads';

interface VirtualizedListProps {
  ads: AdCardForm[];
}

const VirtualizedList: React.FC<VirtualizedListProps> = ({ ads }) => {
  const parentRef = useRef<HTMLDivElement>(null);

  const theme = useTheme();
  const isXl = useMediaQuery(theme.breakpoints.up('xl'));
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));

  const columnCount = isXl ? 4 : isSm ? 3 : 2;
  const rowCount = Math.ceil(ads.length / columnCount);

  const columnWidth = parentRef.current
    ? parentRef.current.offsetWidth / columnCount
    : 250;
  const rowHeight = isXl ? 450 : isLg ? 350 : isMd ? 270 : isSm ? 250 : 220;

  const Cell = ({
    columnIndex,
    rowIndex,
    style,
  }: {
    columnIndex: number;
    rowIndex: number;
    style: React.CSSProperties;
  }) => {
    const index = rowIndex * columnCount + columnIndex;
    if (index >= ads.length) return null;

    const ad = ads[index];

    return (
      <Box
        style={{ ...style, padding: '0.5rem' }}
        className="flex justify-center"
      >
        <AdCard key={ad._id} _id={ad._id} title={ad.title} price={ad.price} />
      </Box>
    );
  };

  return (
    <Box
      ref={parentRef}
      sx={{ height: '100vh', width: '100%', overflow: 'hidden' }}
    >
      <Grid
        columnCount={columnCount}
        columnWidth={columnWidth}
        height={window.innerHeight}
        rowCount={rowCount}
        rowHeight={rowHeight}
        width={parentRef.current?.offsetWidth || 800}
      >
        {Cell}
      </Grid>
    </Box>
  );
};

export default VirtualizedList;
