import React from 'react';
import InputMask from 'react-input-mask';
import { TextField } from '@mui/material';

interface NumberInputProps {
  id?: string;
  name: string;
  type: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  placeholder?: string;
  required?: boolean;
  fullWidth?: boolean;
  variant?: 'outlined' | 'filled' | 'standard';
  sx?: object;
  InputProps?: object;
  helperText?: string;
  error?: boolean;
  mask?: string;
  maskChar?: string | null;
}

export const AuthInput: React.FC<NumberInputProps> = ({
  id,
  name,
  type,
  value,
  onChange,
  onBlur,
  placeholder,
  required,
  fullWidth = true,
  variant = 'outlined',
  sx,
  helperText,
  error = false,
  InputProps,
  mask,
  maskChar = null,
}) => {
  return mask ? (
    <InputMask
      mask={mask}
      maskChar={maskChar}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    >
      {(inputProps) => (
        <TextField
          {...inputProps}
          id={id}
          name={name}
          type={type}
          placeholder={placeholder}
          onBlur={onBlur}
          required={required}
          fullWidth={fullWidth}
          variant={variant}
          helperText={helperText}
          error={error}
          InputProps={InputProps}
          sx={{
            mt: 1,
            mb: 1,
            '& .MuiOutlinedInput-root': {
              height: '50px',
              borderRadius: '9px',
              '& fieldset': {
                borderWidth: '1px',
              },
              '&:hover fieldset': {
                borderWidth: '2px',
                borderColor: 'gray.500',
              },
            },
            ...sx,
          }}
        />
      )}
    </InputMask>
  ) : (
    <TextField
      id={id}
      name={name}
      type={type}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder}
      required={required}
      fullWidth={fullWidth}
      variant={variant}
      helperText={helperText}
      error={error}
      InputProps={InputProps}
      sx={{
        mt: 1,
        mb: 1,
        '& .MuiOutlinedInput-root': {
          height: '50px',
          borderRadius: '9px',
          '& fieldset': {
            borderWidth: '1px',
          },
          '&:hover fieldset': {
            borderWidth: '2px',
            borderColor: 'gray.500',
          },
        },
        ...sx,
      }}
    />
  );
};

export default AuthInput;
