import React from 'react';
import { LinearProgress, Box, Typography } from '@mui/material';

interface PasswordStrengthBarProps {
  password: string;
}

const PasswordStrengthBar: React.FC<PasswordStrengthBarProps> = ({
  password,
}) => {
  const [strength, setStrength] = React.useState<number>(0);
  React.useEffect(() => {
    calculateStrength(password);
  }, [password]);

  const calculateStrength = (password: string) => {
    let score = 0;
    if (password.length < 8) return setStrength(0);
    if (/[A-Z]/.test(password)) score++;
    if (/[a-z]/.test(password)) score++;
    if (/\d/.test(password)) score++;
    if (/[^A-Za-z0-9]/.test(password)) score++;
    setStrength(score);
    return;
  };

  const strengthText = [
    'Senha fraca',
    'Senha razoável',
    'Senha forte',
    'Muito segura!',
  ];
  const progress = (strength / 4) * 100;
  const color =
    ['#F44336', '#FFC107', '#bde030', '#4CAF50'][strength - 1] || '#a9a9a9';

  return (
    <Box mt={1}>
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{
          height: 8,
          borderRadius: 4,
          backgroundColor: '#E0E0E0',
          '& .MuiLinearProgress-bar': { backgroundColor: color },
        }}
      />
      <Typography
        variant="caption"
        color={color}
        sx={{ display: 'block', textAlign: 'right', mt: 0.5 }}
      >
        {strengthText[strength - 1] || ''}
      </Typography>
    </Box>
  );
};

export default PasswordStrengthBar;
