import React, { Suspense } from 'react';
import {
  Autocomplete,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  Link,
  Radio,
  TextField,
  Snackbar,
  Alert,
  CircularProgress,
} from '@mui/material';
import AuthButton from 'src/components/auth/AuthButton';
import AuthInput from 'src/components/auth/AuthInput';
import NumberInput from 'src/components/auth/NumberInput';
import { useRegister } from 'src/hooks/mutations/auth/useRegister';
import PasswordStrengthBar from 'src/components/auth/PasswordStrengthBar';
import {
  validateFistName,
  validateLastName,
  validateEmail,
  validatePassword,
  validateConfirmPassword,
  validateProfession,
  validatePhone,
  validateGender,
  validateBirthDate,
} from 'src/utils/validations';

const SuccessAlert = React.lazy(() => import('src/components/SuccessAlert'));

export const RegisterPage: React.FC = () => {
  const {
    navigate,
    isSmallScreen,
    professionOptions,
    handleProfessionChange,
    formData,
    setFormData,
    validationErrors,
    setValidationErrors,
    passwordVisible,
    confirmPassword,
    setConfirmPassword,
    showAllErrors,
    termsAccepted,
    setTermsAccepted,
    showErrors,
    setShowErrors,
    errorTermsAccepted,
    setErrorTermsAccepted,
    showSuccessAlert,
    setShowSuccessAlert,
    handlePasswordVisibility,
    handleSubmit,
    error,
    isError,
    isPending,
  } = useRegister();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      bgcolor="white"
      px={2}
    >
      <Box
        maxWidth={isSmallScreen ? '90%' : 700}
        width="100%"
        p={6}
        borderRadius={2}
        bgcolor="white"
      >
        <Box display="flex" justifyContent="center" mb={1}>
          <Box
            component="img"
            src="images/logo-todosganham.png"
            alt="Logo"
            sx={{
              width: isSmallScreen ? 48 : 86,
              height: isSmallScreen ? 48 : 86,
            }}
          />
        </Box>
        <Typography
          variant={isSmallScreen ? 'h5' : 'h4'}
          align="center"
          gutterBottom
        >
          Crie sua conta!
        </Typography>
        <Typography
          variant="body1"
          color="grey.900"
          align="center"
          mt={4}
          mb={3}
        >
          Cadastre-se com seu endereço de e-mail
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          display="flex"
          flexDirection="column"
          gap={2}
        >
          <Box
            display="flex"
            flexDirection={isSmallScreen ? 'column' : 'row'}
            gap={2}
          >
            <Box flex={1}>
              <Typography
                variant="body2"
                component="label"
                htmlFor="firstname"
                sx={{ mb: 1, color: 'black' }}
              >
                Nome
              </Typography>
              <AuthInput
                id="firstName"
                fullWidth
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={(e) => {
                  setFormData({ ...formData, firstName: e.target.value });
                  setValidationErrors({
                    ...validationErrors,
                    firstName: validateFistName(e.target.value),
                  });
                }}
                onBlur={() =>
                  setShowErrors((prev) => ({ ...prev, firstName: true }))
                }
                error={showErrors.firstName && !!validationErrors.firstName}
                helperText={
                  showErrors.firstName && validationErrors.firstName
                    ? validationErrors.firstName
                    : ''
                }
              />
            </Box>
            <Box flex={1}>
              <Typography
                variant="body2"
                component="label"
                htmlFor="lastname"
                sx={{ mb: 1, color: 'black' }}
              >
                Sobrenome
              </Typography>
              <AuthInput
                id="lastName"
                fullWidth
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={(e) => {
                  setFormData({ ...formData, lastName: e.target.value });
                  setValidationErrors({
                    ...validationErrors,
                    lastName: validateLastName(e.target.value),
                  });
                }}
                onBlur={() =>
                  setShowErrors((prev) => ({ ...prev, lastName: true }))
                }
                error={showErrors.lastName && !!validationErrors.lastName}
                helperText={
                  showErrors.lastName && validationErrors.lastName
                    ? validationErrors.lastName
                    : ''
                }
              />
            </Box>
          </Box>
          <Box>
            <Typography
              variant="body2"
              component="label"
              htmlFor="email"
              sx={{ mb: 1, color: 'black' }}
            >
              Seu endereço de e-mail
            </Typography>
            <AuthInput
              id="email"
              fullWidth
              type="text"
              inputMode="email"
              autoComplete="email"
              name="email"
              value={formData.email}
              onChange={(e) => {
                setFormData({ ...formData, email: e.target.value });
                setValidationErrors({
                  ...validationErrors,
                  email: validateEmail(e.target.value),
                });
              }}
              onBlur={() => setShowErrors((prev) => ({ ...prev, email: true }))}
              error={showErrors.email && !!validationErrors.email}
              helperText={
                showErrors.email && validationErrors.email
                  ? validationErrors.email
                  : ''
              }
            />
          </Box>
          <Box
            display="flex"
            flexDirection={isSmallScreen ? 'column' : 'row'}
            gap={2}
          >
            <Box flex={1}>
              <Typography
                variant="body2"
                component="label"
                htmlFor="password"
                sx={{ mb: 1, color: 'black' }}
              >
                Senha
              </Typography>
              <AuthInput
                id="password"
                fullWidth
                name="password"
                type={passwordVisible ? 'text' : 'password'}
                value={formData.password}
                onChange={(e) => {
                  setFormData({ ...formData, password: e.target.value });
                  setValidationErrors({
                    ...validationErrors,
                    password: validatePassword(e.target.value),
                    confirmPassword: validateConfirmPassword(
                      confirmPassword,
                      e.target.value,
                    ),
                  });
                }}
                onBlur={() =>
                  setShowErrors((prev) => ({ ...prev, password: true }))
                }
                error={showErrors.password && !!validationErrors.password}
                helperText={
                  showErrors.password && validationErrors.password
                    ? validationErrors.password
                    : ''
                }
              />
            </Box>
            <Box flex={1}>
              <Typography
                variant="body2"
                component="label"
                htmlFor="confirmPassword"
                sx={{ mb: 1, color: 'black' }}
              >
                Confirme sua senha
              </Typography>
              <AuthInput
                id="confirmPassword"
                fullWidth
                name="confirmPassword"
                type={passwordVisible ? 'text' : 'password'}
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  setValidationErrors({
                    ...validationErrors,
                    confirmPassword: validateConfirmPassword(
                      e.target.value,
                      formData.password,
                    ),
                  });
                }}
                onBlur={() =>
                  setShowErrors((prev) => ({ ...prev, confirmPassword: true }))
                }
                error={
                  showErrors.confirmPassword &&
                  !!validationErrors.confirmPassword
                }
                helperText={
                  showErrors.confirmPassword && validationErrors.confirmPassword
                    ? validationErrors.confirmPassword
                    : ''
                }
              />
            </Box>
          </Box>
          <PasswordStrengthBar password={formData.password} />
          <Typography variant="body2" color="textSecondary">
            Use pelo menos 8 caracteres, incluindo letras maiúsculas e
            minúsculas, números e símbolos.
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={passwordVisible}
                onChange={handlePasswordVisibility}
                sx={{
                  '&.Mui-checked': {
                    color: 'black',
                  },
                  color: 'black',
                }}
              />
            }
            label={
              <Typography
                variant="body2"
                sx={{ fontSize: isSmallScreen ? '0.75rem' : '0.875rem' }}
              >
                Mostrar senha
              </Typography>
            }
          />
          <Box>
            <Typography
              variant="body2"
              component="label"
              htmlFor="profession"
              sx={{ mb: 1, color: 'black' }}
            >
              Profissão
            </Typography>
            <Autocomplete
              fullWidth
              options={professionOptions}
              value={formData.profession || ''}
              onChange={(event, value) => {
                handleProfessionChange(event, value);
                setValidationErrors({
                  ...validationErrors,
                  profession: validateProfession(
                    value || '',
                    professionOptions,
                  ),
                });
              }}
              onBlur={() =>
                setShowErrors((prev) => ({ ...prev, profession: true }))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Pesquise e selecione uma profissão"
                  error={showErrors.profession && !!validationErrors.profession}
                  helperText={
                    showErrors.profession && validationErrors.profession
                      ? validationErrors.profession
                      : ''
                  }
                />
              )}
              sx={{
                mt: 1,
                mb: 1,
                '& .MuiOutlinedInput-root': {
                  height: '52px',
                  borderRadius: '9px',
                  '& fieldset': {
                    borderWidth: '1px',
                  },
                  '&:hover fieldset': {
                    borderWidth: '2px',
                    borderColor: 'gray.500',
                  },
                },
                '& .MuiAutocomplete-listbox': {
                  maxHeight: '200px',
                  overflow: 'auto',
                },
              }}
            />
          </Box>
          <Box>
            <Typography
              variant="body2"
              component="label"
              htmlFor="phone"
              sx={{ mb: 1, color: 'black' }}
            >
              Número de telefone
            </Typography>
            <NumberInput
              id="phone"
              fullWidth
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={(e) => {
                setFormData({ ...formData, phone: e.target.value });
                setValidationErrors({
                  ...validationErrors,
                  phone: validatePhone(e.target.value),
                });
              }}
              onBlur={() => setShowErrors((prev) => ({ ...prev, phone: true }))}
              error={showErrors.phone && !!validationErrors.phone}
              helperText={
                showErrors.phone && validationErrors.phone
                  ? validationErrors.phone
                  : ''
              }
              mask="(99) 99999-9999"
            />
          </Box>
          <Box
            display="flex"
            flexDirection={isSmallScreen ? 'column' : 'row'}
            gap={2}
          >
            <Box>
              <Typography
                variant="body2"
                component="label"
                htmlFor="gender"
                sx={{ color: 'black' }}
              >
                Gênero
              </Typography>
              <Box
                display="flex"
                flexDirection={isSmallScreen ? 'column' : 'row'}
                gap={1}
              >
                {['Masculino', 'Feminino', 'Outro'].map((option) => (
                  <FormControlLabel
                    key={option}
                    control={
                      <Radio
                        checked={formData.gender === option}
                        onChange={(e) => {
                          setFormData({ ...formData, gender: e.target.value });
                          setValidationErrors({
                            ...validationErrors,
                            gender: validateGender(e.target.value),
                          });
                        }}
                        onBlur={() =>
                          setShowErrors((prev) => ({ ...prev, gender: true }))
                        }
                        value={option}
                        name="gender"
                        size="small"
                        sx={{
                          color: 'black',
                          '&.Mui-checked': {
                            color: 'black',
                          },
                        }}
                      />
                    }
                    label={<Typography variant="body2">{option}</Typography>}
                  />
                ))}
              </Box>
              {showErrors.gender && validationErrors.gender && (
                <Typography variant="body2" color="error">
                  {showErrors.gender && validationErrors.gender
                    ? validationErrors.gender
                    : ''}
                </Typography>
              )}
            </Box>

            <Box flex={1}>
              <Typography
                variant="body2"
                component="label"
                htmlFor="birthDate"
                sx={{ mb: 1, color: 'black' }}
              >
                Data de nascimento
              </Typography>
              <AuthInput
                id="birthDate"
                fullWidth
                type="date"
                name="birthDate"
                value={
                  formData.birthDate instanceof Date &&
                  !isNaN(formData.birthDate.getTime())
                    ? formData.birthDate.toISOString().split('T')[0]
                    : ''
                }
                onChange={(e) => {
                  const selectedDate = e.target.value;
                  if (selectedDate) {
                    const formattedDate = new Date(selectedDate);
                    setFormData({
                      ...formData,
                      birthDate: formattedDate,
                    });
                    setValidationErrors({
                      ...validationErrors,
                      birthDate: validateBirthDate(formattedDate),
                    });
                  }
                }}
                onBlur={() =>
                  setShowErrors((prev) => ({ ...prev, birthDate: true }))
                }
                error={showErrors.birthDate && !!validationErrors.birthDate}
                helperText={
                  showErrors.birthDate && validationErrors.birthDate
                    ? validationErrors.birthDate
                    : ''
                }
              />
            </Box>
          </Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={termsAccepted}
                onChange={(event) => {
                  setTermsAccepted(event.target.checked);
                  setErrorTermsAccepted(false);
                }}
                sx={{
                  '&.Mui-checked': {
                    color: 'black',
                  },
                  color: 'black',
                }}
              />
            }
            label={
              <Typography
                variant="body2"
                sx={{
                  fontSize: isSmallScreen ? '0.75rem' : '0.9rem',
                  color: '#000',
                }}
              >
                Li, entendi e concordo com os{' '}
                <Link
                  href="/terms-of-use"
                  underline="always"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ color: '#000' }}
                >
                  Termos de uso
                </Link>{' '}
                e{' '}
                <Link
                  href="/privacy-policy"
                  underline="always"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ color: '#000' }}
                >
                  Política de Privacidade
                </Link>
                .
              </Typography>
            }
            sx={{ mb: -1, mt: -2 }}
          />
          {errorTermsAccepted && (
            <Snackbar
              open={true}
              autoHideDuration={6000}
              onClose={() => {
                setErrorTermsAccepted(false);
              }}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
              <Alert severity="error" sx={{ width: '100%' }}>
                Você deve aceitar os termos para continuar.
              </Alert>
            </Snackbar>
          )}
          <AuthButton
            fullWidth
            type="submit"
            variant="contained"
            color="primary"
            disabled={
              isPending ||
              (showAllErrors &&
                Object.values(validationErrors).some((error) => error !== ''))
            }
            sx={{ mt: 2 }}
          >
            {isPending ? 'Processando...' : 'Cadastrar-se'}
          </AuthButton>
          <Typography variant="body2" align="center" sx={{ color: '#000' }}>
            Já tem uma conta?{' '}
            <Link href="/login" underline="always" sx={{ color: '#000' }}>
              Entrar
            </Link>
          </Typography>
        </Box>
      </Box>
      {showSuccessAlert && (
        <Suspense fallback={<CircularProgress />}>
          <SuccessAlert
            message="O cadastro foi um sucesso!"
            buttonText="Entrar"
            onClose={() => {
              setShowSuccessAlert(false);
              navigate('/login');
            }}
          />
        </Suspense>
      )}
      {isError && error && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert severity="error" sx={{ width: '100%' }}>
            {error.message || 'Erro desconhecido.'}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default RegisterPage;
