import { useState, useEffect } from 'react';
import { getProfessions, ProfessionForm } from 'src/api/professions';

export const useProfessionsList = () => {
  const [professionsList, setProfessionsList] = useState<ProfessionForm[]>([]);

  useEffect(() => {
    const fetchProfessions = async () => {
      try {
        const professionsData = await getProfessions();
        setProfessionsList(professionsData);
      } catch (err) {
        setProfessionsList([
          { _id: 'ID_BELONGING_TO_OBJECT_OUTRO', name: 'Outro' },
        ]);
        console.error('Erro ao buscar profissões: ', err);
      }
    };

    fetchProfessions();
  }, []);

  return { professionsList };
};

export const useProfessionOptions = () => {
  const [professionOptions, setProfessionOptions] = useState<string[]>([]);

  useEffect(() => {
    const fetchProfessions = async () => {
      try {
        const professions = await getProfessions();
        setProfessionOptions([
          ...professions.map((profession) => profession.name),
        ]);
      } catch (error) {
        console.error('Erro ao buscar profissões:', error);
        setProfessionOptions(['Outro']);
      }
    };

    fetchProfessions();
  }, []);

  return { professionOptions };
};
