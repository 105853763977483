import React from 'react';
import { TextField } from '@mui/material';

interface AuthInputProps {
  id?: string;
  name: string;
  type?: string;
  value: string;
  margin?: 'none' | 'dense' | 'normal';
  autoFocus?: boolean;
  className?: string;
  inputMode?:
    | 'none'
    | 'search'
    | 'text'
    | 'email'
    | 'tel'
    | 'url'
    | 'numeric'
    | 'decimal';
  autoComplete?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  placeholder?: string;
  required?: boolean;
  fullWidth?: boolean;
  variant?: 'outlined' | 'filled' | 'standard';
  sx?: object;
  InputProps?: object;
  helperText?: string;
  error?: boolean;
}

const AuthInput: React.FC<AuthInputProps> = ({
  id,
  name,
  type,
  value,
  autoFocus = false,
  className,
  inputMode,
  autoComplete,
  margin,
  onChange,
  onBlur,
  placeholder,
  required,
  fullWidth = true,
  variant = 'outlined',
  sx,
  helperText,
  error = false,
  InputProps,
}) => {
  return (
    <TextField
      id={id}
      name={name}
      type={type}
      value={value}
      autoFocus={autoFocus}
      className={className}
      inputMode={inputMode}
      autoComplete={autoComplete}
      margin={margin}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder}
      required={required}
      fullWidth={fullWidth}
      variant={variant}
      helperText={helperText}
      error={error}
      InputProps={InputProps}
      sx={{
        mt: 1,
        mb: 1,
        '& .MuiOutlinedInput-root': {
          height: '50px',
          borderRadius: '9px',
          '& fieldset': {
            borderWidth: '1px',
          },
          '&:hover fieldset': {
            borderWidth: '2px',
            borderColor: 'gray.500',
          },
        },
        ...sx,
      }}
    />
  );
};

export default AuthInput;
