import { useState } from 'react';
import { Box, Divider, TextField, Typography } from '@mui/material';

interface PriceFilterProps {
  onPriceChange: (priceRange: [number | null, number | null]) => void;
}

const PriceFilter: React.FC<PriceFilterProps> = ({ onPriceChange }) => {
  const [minPrice, setMinPrice] = useState<string>('');
  const [maxPrice, setMaxPrice] = useState<string>('');

  const validPriceRegex = /^\d*(?:[.,]\d{0,2})?$/;

  const updatePrice = (min: string, max: string) => {
    const parsePrice = (value: string) => Number(value.replace(',', '.'));

    if (min && max) {
      const numMin = parsePrice(min);
      const numMax = parsePrice(max);
      const newPrices: [number, number] =
        numMin > numMax ? [numMax, numMin] : [numMin, numMax];
      onPriceChange(newPrices);
    } else if (min) {
      const numMin = parsePrice(min);
      onPriceChange([numMin, null]);
    } else if (max) {
      const numMax = parsePrice(max);
      onPriceChange([null, numMax]);
    } else {
      onPriceChange([null, null]);
    }
  };

  const handleMinChange = (value: string) => {
    if (!validPriceRegex.test(value)) return;
    setMinPrice(value);
    updatePrice(value, maxPrice);
  };

  const handleMaxChange = (value: string) => {
    if (!validPriceRegex.test(value)) return;
    setMaxPrice(value);
    updatePrice(minPrice, value);
  };

  const displayPrice = () => {
    if (minPrice && maxPrice) {
      const numMin = Number(minPrice.replace(',', '.'));
      const numMax = Number(maxPrice.replace(',', '.'));
      const [a, b] = numMin > numMax ? [numMax, numMin] : [numMin, numMax];
      return `${a.toFixed(2)} BRL - ${b.toFixed(2)} BRL`;
    } else if (minPrice) {
      const num = Number(minPrice.replace(',', '.'));
      return `${num.toFixed(2)} BRL`;
    } else if (maxPrice) {
      const num = Number(maxPrice.replace(',', '.'));
      return `${num.toFixed(2)} BRL`;
    }
    return 'Valor não definido';
  };

  return (
    <Box
      className="mb-2 rounded-lg border bg-white"
      sx={{ border: '1px solid #ccc' }}
    >
      <Typography variant="h6" className="p-1 pl-3 text-gray-500">
        Preço
      </Typography>
      <Divider sx={{ backgroundColor: '#ccc' }} />
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', m: 2 }}>
        <TextField
          label="Mínimo"
          placeholder="ex: 10.50"
          variant="outlined"
          size="small"
          value={minPrice}
          onChange={(e) => handleMinChange(e.target.value)}
          sx={{ width: '50%' }}
        />
        <TextField
          label="Máximo"
          placeholder="ex: 1050.00"
          variant="outlined"
          size="small"
          value={maxPrice}
          onChange={(e) => handleMaxChange(e.target.value)}
          sx={{ width: '50%' }}
        />
      </Box>
      <Typography variant="body2" className="pb-1 text-center text-gray-700">
        {displayPrice()}
      </Typography>
    </Box>
  );
};

export default PriceFilter;
