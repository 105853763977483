import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: '"Poppins", sans-serif',
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#09A3C8',
    },
    secondary: {
      main: '#880033',
    },
    background: {
      default: '#f8f8f8',
      paper: '#ffffff',
    },
    text: {
      primary: '#0a0a0a',
      secondary: '#000000',
    },
  },
});

const responsiveTheme = responsiveFontSizes(theme);

export default responsiveTheme;
