import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from 'src/hooks/useAuth';
/**
 * ProtectedRoute
 *
 * A component to protect routes that require authentication.
 * Redirects to "/login" if the user is not authenticated.
 * Otherwise, renders the content of the protected route using <Outlet />.
 *
 * @Component
 * @returns {JSX.Element} Redirect or protected route content.
 */
export const ProtectedRoute: React.FC = () => {
  const { token } = useAuth();

  if (!token) {
    return <Navigate to="/login" />;
  }
  return <Outlet />;
};
